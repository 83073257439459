#search-auto-complete {
    width: auto !important;
    padding-left: 3em !important;
    color: #efefef !important;
}

.search-content .MuiOutlinedInput-root {
    padding: 0 !important;
}

.search-content {
    width: 550px;
}

.MuiAutocomplete-endAdornment {
    display: none !important;
}

.MuiAutocomplete-option {
    justify-content: space-between !important;
}


.price-type {
    font-size: 0.5rem;
    font-weight: 600;
    color: #0c4128;
}

.bg-light-price {
    background: #e5e5e5;
    padding: 7px;
    border-radius: 15px;
}

.MuiAutocomplete-option[aria-disabled="true"] {
    opacity: 1 !important;
}


@media (max-width: 478px) {
    #search-auto-complete {
        width: 12ch !important;
    }

    .search-content .MuiAutocomplete-popper {
        width: 100vw !important;
    }
}
