p {
  margin-bottom: 0;
}

.btn-top-right {
  position: absolute !important;
  top: -15px;
  right: -10px;
  z-index: 999;
}

.text-description {
  white-space: break-spaces;
}
