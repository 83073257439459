.text-description-gallery {
    color: #dadada !important;
    height: 60%;
    overflow-y: auto;
}

.content-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.content-scroll::-webkit-scrollbar:vertical {
    width:8px;
}

.content-scroll::-webkit-scrollbar-button:increment,.content-scroll::-webkit-scrollbar-button {
    display: none;
}

.content-scroll::-webkit-scrollbar:horizontal {
    height: 8px;
}

.content-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(134, 134, 134, 0.76);
    border-radius: 20px;
//border: 2px solid #f1f2f3;
}

.content-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
}


@media (min-width: 768px) {
    *::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    *::-webkit-scrollbar:vertical {
        width:6px;
    }

    *::-webkit-scrollbar-button:increment,*::-webkit-scrollbar-button {
        display: none;
    }

    *::-webkit-scrollbar:horizontal {
        height: 6px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: rgba(160, 160, 160, 0.76);
        border-radius: 15px;
    //border: 2px solid #f1f2f3;
    }

    *::-webkit-scrollbar-track {
        border-radius: 10px;
    }

}
