td,
th,
tr,
table {
    border-top: 1px solid black;
    border-collapse: collapse;
}

td.producto,
th.producto {
    width: 75px;
    max-width: 75px;
}

td.cantidad,
th.cantidad {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

td.precio,
th.precio {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
    text-align: right;
}

td.subtotal,
th.subtotal {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
    text-align: right;
}

.centrado {
    text-align: center;
    align-content: center;
}

.title {
    font-size: 16px;
    font-weight: bold;
}

.title-ticket {
    font-size: 23px;
    font-weight: bold;
}

.fs-7 {
    font-size: 0.8rem;
}

.ticket {
    width: 175px;
    max-width: 175px;
    font-size: 12px;
    font-stretch: extra-expanded;
    /*font-family: 'Times New Roman';*/
    color: black;
}

img {
    max-width: inherit;
    width: 30%;
}

@media print {
    .MuiPaper-root,
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
    }
    .MuiContainer-root {
        margin: 0 !important;
        padding: 0 !important;
    }
    .mx-md-auto {
        margin: 0 !important;
    }
}

@page {
    margin: 5px;
}
